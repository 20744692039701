<template>
  <div class="container my-4">
    <div class="accordion" id="accordionCirculares">
      <!-- Acordeón de circulares para 2024 -->
      <div class="accordion-item">
        <div class="accordion-header" id="heading2024">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse2024"
            aria-expanded="false"
            aria-controls="collapse2024"
          >
            <h2 class="fw-bold ">
              CIRCULARES DEL AÑO 2024
            </h2>
          </button>
        </div>
        <div
          id="collapse2024"
          class="accordion-collapse collapse"
          aria-labelledby="heading2024"
          data-bs-parent="#accordionCirculares"
        >
          <div class="accordion-body">
            <div class="row">
              <!-- Card para cada circular en 2024 -->
              <div class="col-md-2" 
                  v-for="(circular, index) in circulares2024" 
                  :key="circular.id"
                  :class="{ active: index === circulares2024.length - 13 }">
                <div class="card mb-4">
                  <h4 class="card-header fw-bold">
                    CIRCULAR N°
                  </h4>
                  <div class="card-body text-center">
                    <h1 class="display-4">{{ circular.numero }}</h1>
                    <p class="card-text">{{ circular.nombre }}</p>
                    <button
                      class="btn btn-outline-primary"
                      @click="openModal(circular.pdfUrl, circular.nombre)"
                    >
                    <i class="bi bi-eye"></i> Ver PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal shadow rounded fade"
      id="pdfModal"
      tabindex="-1"
      aria-labelledby="pdfModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header  ">
            <h5 class="modal-title fs-5 fw-bold" id="pdfModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <embed :src="pdfSrc" type="application/pdf" width="100%" height="600px" />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      pdfSrc: '',
      modalTitle: '', 
      circulares2024: [
        { id: 11, numero: '12', nombre: 'Actividades del Cuarto Periodo IV', pdfUrl: 'https://drive.google.com/file/d/11bE9QdKrJirfJdPDwlJXfxZ9uuJ04KJu/preview' },
        { id: 11, numero: '11', nombre: 'Costos de Mi Saber Visionario, Prueba externa y otras actividades.', pdfUrl: 'https://drive.google.com/file/d/1bHR6W9BMzSzuzX55SC_QipvmQEZNXv72/preview' },
        { id: 10, numero: '10', nombre: 'Actividades del Tercer Periodo Academico.', pdfUrl: 'https://drive.google.com/file/d/1hKKTI0lLF7ApJU3F9PwzdQTWS3kngHak/preview' },
        { id: 9, numero: '09', nombre: 'Prueba externa II periodo, Plan de nivelación y Receso de Vacaciones.', pdfUrl: 'https://drive.google.com/file/d/1wCkWOOIYe1GehtURPXINV4JE18QYqLOi/preview' },
        { id: 8, numero: '08', nombre: 'Actividad cultural FestiSinú 2024.', pdfUrl: 'https://drive.google.com/file/d/1_wc2f_k7-zAXEjZ79FOpCidi9n55uWba/preview' },
        { id: 7, numero: '07', nombre: 'Actividades del Segundo Periodo Academico.', pdfUrl: 'https://drive.google.com/file/d/1dtEjRR8zHup6QImxb71g6anz5f6H1o3r/preview' },
        { id: 6, numero: '06', nombre: 'Costos de Mi Saber Visionario y Otras Actividades.', pdfUrl: 'https://drive.google.com/file/d/1BwGG04UADgPUa0Iaev3h0G3Oq0stpvy0/preview' },
        { id: 5, numero: '05', nombre: 'Salida a Receso y Finalización del Primer Periodo.', pdfUrl: 'https://drive.google.com/file/d/10ciEPbF4M4loiXAYVC-xlDHNlyfMj1nc/preview' },
        { id: 4, numero: '04', nombre: 'Padres de Familia desde Preescolar hasta Grado 11°.', pdfUrl: 'https://drive.google.com/file/d/1IWd8RM91uvp0P2iqJZzrA_XQRKjvWjdh/preview' },
        { id: 3, numero: '03', nombre: 'Padres de Familia desde Grado 2° hasta Grado 11°.', pdfUrl: 'https://drive.google.com/file/d/1lBicZb8hACzgnIXnHMmYO4acl8ZOV5AQ/preview' },
        { id: 2, numero: '02', nombre: 'Actividades del Primer Periodo Academico.', pdfUrl: 'https://drive.google.com/file/d/1vfZPVAslg_6ABCxoLcN_NLCahtaN0c9i/preview' },
        { id: 1, numero: '01', nombre: 'Instrucciones de Ingreso Plataforma Classroom.', pdfUrl: 'https://drive.google.com/file/d/1jm_3pogaZeXoqHdOPIIRQw9Pa7F1QkHf/preview' },
        { id: 0, numero: '00', nombre: 'Información General de Inicio de Año.', pdfUrl: 'https://drive.google.com/file/d/1b3KedkcjxtFZZfWE9yxw43Tbtc9oUR-A/preview' },


        // Agrega más circulares aquí
      ]
    };
  },
  methods: {
    openModal(pdfUrl,title) {
      if (pdfUrl && this.validateUrl(pdfUrl)) {
      this.pdfSrc = pdfUrl;
      this.pdfError = false; // Resetear el error si el enlace es válido
      this.modalTitle = title; // Establecer el título del modal
      } else {
      this.pdfSrc = '';
      this.pdfError = true; // Mostrar error si el enlace no es válido
      this.modalTitle = 'Recurso no disponible'; // Título de error
    }
      const myModal = new Modal(document.getElementById('pdfModal'));
      myModal.show();
    },

    validateUrl(pdfUrl) {
    // Puedes agregar más lógica de validación si es necesario
    return pdfUrl.includes('drive.google.com'); // Ejemplo de validación básica
  },
    openAccordionAndModal() {
      // Abre el primer acordeón
      const firstAccordionButton = document.querySelector('#accordionCirculares .accordion-button');
      if (firstAccordionButton) {
        firstAccordionButton.click();
      }
    }
  },
  mounted() {
    this.openAccordionAndModal();
  }
};
</script>

<style scoped>


.modal-title{
  color: var(--color-pr);
  text-transform: uppercase;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Asegura que el texto no se envuelva */
  max-width: 100%; /* Limita el ancho del texto al del contenedor */
  max-height: 100%; /* Limita el ancho del texto al del contenedor */
}

.btn{
  color: var(--color-se);
  border: var(--color-se);
}

.btn:hover{
  color: var(--color-co);
}

.card-body h1 {
  font-size: 2rem; /* Ajusta el tamaño del número según tu diseño */
}

.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Suavizar la transformación */
}

.card:hover,
.active .card {
  transform: scale(1.05) rotate(2deg); /* Agrandar y rotar ligeramente */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Añadir una sombra más pronunciada */
}

.card:hover .card-header,
.active .card-header {
  background-color: var(--color-pr); /* Color naranja para el header cuando se hace hover o es activo */
  color: #ffdacb;
}

.card:hover .card-body,
.active .card-body {
  background-color: #ffdacb; /* Color naranja claro para el body cuando se hace hover o es activo */
}
</style>